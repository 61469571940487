@import url("https://fonts.googleapis.com/css2?family=Alata&family=Josefin+Sans:wght@300&display=swap");

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --l-gray: hsl(0, 0%, 55%);
  --d-gray: hsl(0, 0%, 41%);
  --footer-bg: hsl(0, 0%, 0%);
  --footer-color: hsl(0, 0%, 100%);
  --gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  --gradient-hover: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.8) 0%,
    hsla(0, 0%, 100%, 0.8) 100%
  );

  --font-p: "Alata", sans-serif;
  --font-s: "Josefin Sans", sans-serif;
}

body {
  font-family: var(--font-p);
  background: var(--white);
  color: var(--black);
}

body.dark {
  --black: hsl(0, 0%, 100%);
  --white: hsl(0, 0%, 0%);
}
